<template>
  <div class="page page--terms terms">
    <div v-if="$i18n.locale === 'nl'">
      <h1>Betaalnummer</h1>
      <p>
        Verplichte informatie vereist door de wet van 5/6/2005 artikel 116/1 §1
        “Een derde die een betaalnummer uit het Belgische E.164 nummerplan
        gebruikt verstrekt met het oog op openbaarmaking de volgende gegevens
        aan het in het volgende lid bedoelde register, waarna de nummerhoudende
        operator het mogelijk maakt dit betaalnummer in gebruik te nemen:
      </p>
      <ul class="list">
        <li>
          <p>
            1° de naam, het adres en indien van toepassing het KBO-nummer van de
            dienstenaanbieder;
          </p>
          <p>
            <b
              >Huur een Stuur BV; Wiedauwkaai 98, 9000 Gent, België; KBO-nr.
              0450 825 019</b
            >
          </p>
        </li>
        <li>
          <p>
            2° het EU MOSS of Belgisch btw-nummer van de partij die
            verantwoordelijk is voor de afdracht van de btw over de geïnde
            gelden;
          </p>
          <p><b>BE 0450 825 019</b></p>
        </li>
        <li>
          <p>3° de omschrijving van de dienst;</p>
          <p><b>Klantendienst en nieuwe bestellingen voor personenvervoer van en naar de luchthaven/events</b></p>
        </li>
        <li>
          <p>4° de URL's gebruikt door de dienst;</p>
          <p>
            <b>
              <ul class="list">
                <li>https://www.billo.be</li>
                <li>https://www.billo.be/about</li>
                <li>https://www.billo.be/account</li>
                <li>https://www.billo.be/contact</li>
                <li>https://www.billo.be/events</li>
                <li>https://www.billo.be/faq</li>
                <li>https://www.billo.be/jobs</li>
                <li>https://www.billo.be/pricing</li>
                <li>https://www.billo.be/terms</li>
              </ul>
            </b>
          </p>
        </li>
        <li>
          <p>5° de totale prijs van de dienst;</p>
          <p><b>€0.50/minuut</b></p>
        </li>
        <li>
          <p>
            6° het contactadres, e-mail en nationaal telefoonnummer waarvan de
            gesprekskosten per minuut niet hoger zijn dan deze voor een oproep
            naar een geografisch nummer, voor klachtenbehandeling;
          </p>
          <p>
            <b
              >Wiedauwkaai 98, 9000 Gent, België; contact@billo.be; +32 51 70 26
              45</b
            >
          </p>
        </li>
        <li>
          <p>
            7° indien van toepassing, het nummer van de vergunning volgens de
            wet van 7 mei 1999 op de kansspelen, de weddenschappen, de
            kansspelinrichtingen en de bescherming van de spelers en haar
            uitvoeringsbesluiten;
          </p>
          <p><b>Niet van toepassing.</b></p>
        </li>
        <li>
          <p>8° de startdatum en einddatum van de dienst;</p>
          <p><b>01/01/2016 - 31/12/2099</b></p>
        </li>
        <li>
          <p>
            9° de hierboven vermelde gegevens die de voorbije 6 maanden van
            toepassing waren, indien ze verschillen van de actuele gegevens.
          </p>
          <p><b>Niet van toepassing.</b></p>
        </li>
      </ul>
    </div>
    <div v-else-if="$i18n.locale === 'fr'">
      <h1>Numéro surtaxé</h1>
      <p>
        Informations obligatoires requises par la loi du 5/6/2005 article 116/1 §1
         Un tiers qui utilise un numéro surtaxé du plan de numérotation belge E.164
         utilisé fournit les informations suivantes à des fins de divulgation
         au registre visé à l'alinéa suivant, après quoi le numéro titulaire
         opérateur permet d'utiliser ce numéro surtaxé :
      </p>
      <ul class="list">
        <li>
          <p>
            1° le nom, l'adresse et, le cas échéant, le numéro BCE du
             fournisseur de services;
          </p>
          <p>
            <b
              >Huur een Stuur BV; Wiedauwkaai 98, 9000 Gand, Belgique; KBO-nr.
              0450 825 019</b
            >
          </p>
        </li>
        <li>
          <p>
            2° le numéro EU MOSS ou le numéro de TVA belge de la partie qui
             est responsable du paiement de la TVA;
          </p>
          <p><b>BE 0450 825 019</b></p>
        </li>
        <li>
          <p>3° la description du service ;</p>
          <p><b>Support client et des nouvelles commandes pour le transport de personnes vers et depuis l'aéroport/événements</b></p>
        </li>
        <li>
          <p>4° les URL utilisées par le service ;</p>
          <p>
            <b>
              <ul class="list">
                <li>https://www.billo.be</li>
                <li>https://www.billo.be/about</li>
                <li>https://www.billo.be/account</li>
                <li>https://www.billo.be/contact</li>
                <li>https://www.billo.be/events</li>
                <li>https://www.billo.be/faq</li>
                <li>https://www.billo.be/jobs</li>
                <li>https://www.billo.be/pricing</li>
                <li>https://www.billo.be/terms</li>
              </ul>
            </b>
          </p>
        </li>
        <li>
          <p>5° le prix total de service ;</p>
          <p><b>€0.50/minute</b></p>
        </li>
        <li>
          <p>
            6° l'adresse de contact, l'adresse e-mail et le numéro de téléphone national dont
             les coûts d'appel par minute ne dépassent pas ceux d'un appel
             à un numéro géographique, pour le traitement des réclamations ;
          </p>
          <p>
            <b
              >Wiedauwkaai 98, 9000 Gand, Belgique; contact@billo.be; +32 51 70 26
              45</b
            >
          </p>
        </li>
        <li>
          <p>
            7° le cas échéant, le numéro de licence selon le
             loi du 7 mai 1999 sur les jeux de hasard, les paris, les
             les établissements de jeux et la protection des joueurs et de leurs
             Ordres exécutifs;
          </p>
          <p><b>Ne s'applique pas.</b></p>
        </li>
        <li>
          <p>8° la date de début et la date de fin de la prestation ;</p>
          <p><b>01/01/2016 - 31/12/2099</b></p>
        </li>
        <li>
          <p>
            9° les données précitées collectées au cours des 6 derniers mois de
             étaient applicables si elles différaient des données actuelles.
          </p>
          <p><b>Ne s'applique pas.</b></p>
        </li>
      </ul>
    </div>
    <div v-else-if="$i18n.locale === 'en'">
    <h1>Premium rate number</h1>
      <p>
        Mandatory information required by the law of 5/6/2005 article 116/1 §1
        “A third party who uses a premium rate number from the Belgian E.164 number plan
        used provides the following information for the purpose of disclosure
        to the register referred to in the following paragraph, after which the number holding
        operator makes it possible to use this premium rate number:
      </p>
      <ul class="list">
        <li>
          <p>
            1° the name, address and, if applicable, the CBE number of the
            service provider;
          </p>
          <p>
            <b
              >Huur een Stuur BV; Wiedauwkaai 98, 9000 Ghent, Belgium; CBE no.
              0450 825 019</b>
          </p>
        </li>
        <li>
          <p>
            2° the EU MOSS or Belgian VAT number of the party that
            is responsible for the VAT payment;
          </p>
          <p><b>BE 0450 825 019</b></p>
        </li>
        <li>
          <p>3° the description of the service;</p>
          <p><b>Client-support and new orders for passenger transport to and from the airport/events</b></p>
        </li>
        <li>
          <p>4° the URLs used by the service;</p>
          <p>
            <b>
              <ul class="list">
                <li>https://www.billo.be</li>
                <li>https://www.billo.be/about</li>
                <li>https://www.billo.be/account</li>
                <li>https://www.billo.be/contact</li>
                <li>https://www.billo.be/events</li>
                <li>https://www.billo.be/faq</li>
                <li>https://www.billo.be/jobs</li>
                <li>https://www.billo.be/pricing</li>
                <li>https://www.billo.be/terms</li>
              </ul>
            </b>
          </p>
        </li>
        <li>
          <p>5° the total price of the service;</p>
          <p><b>€0.50/minute</b></p>
        </li>
        <li>
          <p>
            6° the contact address, e-mail and national telephone number of which the
            call costs per minute do not exceed those for a call
            to a geographical number, for complaint handling;
          </p>
          <p>
            <b>Wiedauwkaai 98, 9000 Ghent, Belgium; contact@billo.be; +32 51 70 26
              45</b>
          </p>
        </li>
        <li>
          <p>
            7° if applicable, the license number according to the
            law of 7 May 1999 on games of chance, betting, de
            gambling establishments and the protection of players and their
            executive orders;
          </p>
          <p><b>Not applicable.</b></p>
        </li>
        <li>
          <p>8° the start date and end date of the service;</p>
          <p><b>01/01/2016 - 31/12/2099</b></p>
        </li>
        <li>
          <p>
            9° the above-mentioned data applicable during the past 6 months if they differ from the current data.
          </p>
          <p><b>Not applicable.</b></p>
        </li>
      </ul></div>
  </div>
</template>

<script>
export default {
  name: "Terms",

  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
